.header {
    display: flex;
    text-align: center;
    margin-bottom: 0px;
  }
  .heading h1 {
    font-size: 22px;
    font-weight: 600;
    word-spacing: 10px;
  }
  .heading p {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
  .logo img {
    width: 150px;
    height: 70px;
  }
  
  .seprator {
    height: 2px;
    background-color: black;
  }
  
  .form-container {
    margin: 0px 20px;
  }
  
  .form-heading h3 {
    text-align: center;
    text-decoration: underline;
    margin:0;
  }
   .para h4{
      margin:0;
   
   }
  .line1 {
    display: flex;
  }
  
  .name {
    width: 50%; /* Take half of the screen width */
  }
  
  .sex,
  .age {
    width: 25%; /* Take remaining half (divided into two equal parts) */
  }
  
  /* Adjustments for better presentation (optional) */
  .line1 > div {
    margin: 10px;
  }
  
  p {
    margin: 0; /* Remove default paragraph margin */
  }
  
  .mr-no, .uhid , .ipd, .date{
  width: 25%;
  }
  .sonof{
      width: 100%;
  }
  
  .h4{
    padding: 0;
    margin:0;
  }
  
  .sign{
      display: flex;
     
  }
  .witness-name-sign{
      width: 60%;
  }
  .patients-sign{
      width: 40%;
  }
  .sign-anaes {
      width: 50%; /* Take half of the screen width */
      margin-left:60% ;
  }