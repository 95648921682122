.header {
    display: flex;
    text-align: center;
    margin-bottom: 0px;
  }
  .heading h1 {
    font-size: 22px;
    font-weight: 600;
    word-spacing: 10px;
  }
  .heading p {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }

  .seprator {
    height: 2px;
    background-color: black;
  }
  
  .form-container {
    margin: 0px 20px;
  }
  
  .form-heading h3 {
    text-align: center;
    text-decoration: underline;
    margin:0;
  }
  .line1 {
    display: flex;
  }
  
  .sex,
  .age {
    width: 20%; /* Take remaining half (divided into two equal parts) */
  }
  
  /* Adjustments for better presentation (optional) */
  .line1 > div {
    margin: 25px;
  }
  
  p {
    margin: 0; /* Remove default paragraph margin */
  }
  .history,.presentingcomplaint ,.generalphysical, .painscore{
    width: 100%;
  }

  .tableout{
    width: 100%;
  }

  .tableout tr th{
    border: 1px solid black;
  }

  .tableout tr td{
    border: 1px solid black;
  }


  td{
    height: 50px;
  }
  
  .Provisionaldiagnosis{
    width: 100%;
  }
  .painscore{
    display: flex;
  }

  .painscore .nill{
    margin: 10PX;
    padding: 1px;
  }