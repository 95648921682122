*{
    padding: 0;
    margin: 0;
}
.header {
    display: flex;
    text-align: center;
    margin-bottom: 0px;
  }
  .heading h1 {
    font-size: 22px;
    font-weight: 600;
    word-spacing: 10px;
  }
  .heading p {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
  .logo img {
    width: 150px;
    height: 70px;
  }
  
  .seprator {
    height: 2px;
    background-color: black;
  }
  
  .form-container {
    margin: 0px 20px;
  }
  
  .form-heading h3 {
    text-align: center;
    text-decoration: underline;
    margin:0;
  }
  .line1 {
    display: flex;
  }
  
  .line1 > div {
    margin: 20px;
  }
  
  p {
    margin: 0; /* Remove default paragraph margin */
  }

  .name {
    width: 100%;
    /* Take half of the screen width */
  }
  
 .agesex{
    width: 100%;
 }

 .bed{
    width: 100%;
 }

 .formboxdetails{
    border: 1px solid black;
    margin-right: 20px;
 }


 .line2 > div {
    margin: 0px;
  }

  .line2{
    display: flex;
    margin-left: 10px;
    margin-top: px;
  }

  .provisional{
    width: 100%;
  }

  .Preventative{
    width: 100%;
  }

 

  .border tr th {
    border: 1px solid black;
  }

  .border tr td {
    border: 1px solid black;
  }

  

@media print{
*{
    font-size: 13px;
}

}