.landing-page{
    overflow-x: hidden;
  }
  .back{
      background-color: #b7cedd;
  }
  .navbar-brand{
       font-weight: 500;
  }
  .nav-item a{
   font-weight: 500;
  }
  .home-heading{
    
      font-size: 30px;
      justify-content: center;
  }
  .home-para{
      font-size: 20px;
      justify-content: flex-start;
  
  }
  .main-img img{
      padding: 30px;
      width: 693px;
  height: 598px;
  flex-shrink: 0;
  }
  .landing-page{
      background-color: white;
  }
  #about{
      margin-bottom: 50px;
  }
  .about-heading{
      text-align: center;
  }
  .about-heading p{
      justify-content: center;
  }
  
  .about-img >img{
    width: 75%;
  }
  .card-sec{
      margin-top: 50px;
  }
  .card img{
      width: 30%;
      
  }
  .card-img-top{
      margin:auto;
  }
  .card{
      outline: none;
      margin: 5px;
  }
  
  
  .mission-heading
  {
      text-align: center;
      justify-content: center;
      margin-bottom: 50px;
  }
   .mission-img img{
      height: 500px;
      width: 500px;
  
   }
   
  .cr-img img{
    height: 400px;
    width: 400px;
  }
  
  
  
  
    .footer
    {
      background-color: #b7cedd;
      padding:30px; 
    }
    .quick-link ul {list-style: none;
      padding: 0;
     
    }
  
    .quick-link ul li {
    
    margin-bottom:20px;
  }
  
  
    .socials-links {
      height: 30%;
      width: 30px;
  
      
    }
    img.socials-links{
      margin-bottom: 10px;
    }
    
    .socials-link-add{
      text-decoration: none;
     
    }
    .text-decoration-none{
      list-style: none;
    }
    .google{
      width: 100%;
      height: 100%;
    }
    .copyright{
      font-size: 12px;
    }