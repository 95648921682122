
@media print {
    .button{
      display: none;
    }
    .attendant{
        display: none;
      }
      .patient-btn{
        display: none;
      }
      .doctor ,.attendant-detail{
        display: none;
      }
      .navbar{
        display: none;
      }
      .navbar-toggler{
        display: none;
      }
  }
.modal-btn{
  width: 200px;
 height: 40px;
}
.doctor-btn{
  width: 200px;
}
.custom-modal-width .modal-dialog {
  max-width: 90%; /* Adjust the width as needed */
}