*{
    margin: 0;
    padding: 0;
  }
  .header {
      display: flex;
      text-align: center;
      margin-bottom: 0px;
    }
    .heading h1 {
      font-size: 22px;
      font-weight: 600;
      word-spacing: 10px;
    }
    .heading p {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
    }
   
    
    .seprator {
      height: 2px;
      background-color: black;
    }
    
    .form-container {
      margin: 0px 20px;
    }
    
    .form-heading h3 {
      text-align: center;
      text-decoration: underline;
      margin:0;
    }
  
    .formbutton{
      margin-left: 180vb;
      margin-top: -30px;
    }
     .para h4{
        margin:0;
     
     }
    .line1 {
      display: flex;
    }
    
    .pac {
      width: 80%; /* Take half of the screen width */
    }
  
    .pac1{
      width: 20%;
    }
    
    
    /* Adjustments for better presentation (optional) */
    .line1 > div {
      margin: 10px;
    }
    
    p {
      margin: 0; /* Remove default paragraph margin */
    }
    
    .sonof p{
        width: 100%;
    }
  
    .sonof1{
      width: 80%;
    }
  
    .madi{
      width: 10%;
    }
  
    .proposed {
     margin-left: 100%;
    }
    
    
    