.form-container{
    text-align: justify;
}
.header{
    display: flex;
   text-align: center;
   margin-bottom: 0px;
}
.heading  h1{
    font-size: 22px;
    font-weight: 600;
    word-spacing: 10px;
}
.heading p {
 font-weight: 600;
 font-size: 16px;
 text-align: center;
}

.seprator{
    height: 2px;
    background-color: black;
}

.form-container{
    margin:5px 20px;
   
}

.form-heading h3{
    text-align: center;
    text-decoration: underline;
}
.pre-test-info h5{
    margin:0;
}
.pre-test-info ol{
    margin:2px;
}
@media print{
    *{
        font-size: 14px;
    }
    
}