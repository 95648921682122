*{
    padding: 0;
    margin: 0;
}
.header {
    display: flex;
    text-align: center;
    margin-bottom: 0px;
  }
  .heading h1 {
    font-size: 22px;
    font-weight: 600;
    word-spacing: 10px;
  }
  .heading p {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }

  
  .seprator {
    height: 2px;
    background-color: black;
  }
  
  .form-container {
    margin: 0px 20px;
  }
  
  .form-heading h3 {
    text-align: center;
    text-decoration: underline;
    margin:0;
  }
  .line1 {
    display: flex;
  }
  
  .line1 > div {
    margin: 14px;
  }
  
  p {
    margin: 0; /* Remove default paragraph margin */
  }

  .name {
    width: 50%; /* Take half of the screen width */
  }
  
 .sex{
    width: 50%;
 }

 .consultant{
    width: 100%;
 }

 .datetime{
    width: 100%;
 }

 .mlc{
  width: 15%;
 }

 .yes{
    width: 10%;
 }

 .no{
  width: 15%;
 }

 .mlcno{
  width: 60%;
 }

 .weight{
  width: 50%;
 }

 .yes1{
  width: 25%;
 }

 .no1{
  width: 25%;
 }

 .chief{
  width: 100%;
 }

 .ambulatory{
  width: 100%;
 }

 input.box{
  width: 18px;
  height: 18px;
 }

 .temp{
  width: 100%;
 }

.patienton{
  width: 100%;
}

.ivline{
  width: 100%;
}

.invesigation{
  width: 100%;
}

.kth{
  width: 100%;
}