*{
    padding: 0;
    margin: 0;
}
.header {
    display: flex;
    text-align: center;
    margin-bottom: 0px;
  }
  .heading h1 {
    font-size: 22px;
    font-weight: 600;
    word-spacing: 10px;
  }
  .heading p {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
 
  .list-decoration{
  list-style: none;
  }
  .seprator {
    height: 2px;
    background-color: black;
  }
  
  .form-container {
    margin: 0px 20px;
  }
  
  .form-heading h3 {
    text-align: center;
    text-decoration: underline;
    margin:0;
  }
  .line1 {
    display: flex;
  }
  
  .name {
    width: 50%; /* Take half of the screen width */
  }
  
  .sex {
    width: 30%; /* Take remaining half (divided into two equal parts) */
  }

  .date{
    width: 20%;
  }
  
  .ipd, .ward{
    width: 50%;
  }
  /* Adjustments for better presentation (optional) */

  
  p {
    margin: 0; /* Remove default paragraph margin */
  }
 

  .co, .Vulenerable, .restrain{
    width: 100%;
  }

  .Provisional{
    width: 100%;
  }

  .hrline {
    height: 1px;
    background-color: black;
  }
  .valuable{
    width: 100%;
  }

  .patientlist tr td{
    padding: 10px;
  }

  .Provisional{
    margin: 10px;
  }

  @media print{
  *{
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  }